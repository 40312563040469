import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { MoreBar } from '@revolut/ui-kit'

import { API } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PermissionTypes } from '@src/store/auth/types'
import { employeeRecordsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { EditableEmployeesTable } from '@src/features/EditableEmployeesTable'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { SelectBulkUploadFlow } from '@src/apps/People/Employees/BulkUpload/SelectBulkUploadFlow'
import { BulkEmployeeUploadFlow } from '@src/features/EditableEmployeesTable/BulkEmployeesUploadFlow'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { EmployeeRecordsIntro } from './EmployeeRecordsIntro'
import { BulkContractsUploadFlow } from '@src/features/EditableContractsTable/BulkContractsUploadFlow'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.INTRO,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: EmployeeRecordsIntro,
  },
  {
    title: 'Update',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: () => (
      <EditableEmployeesTable
        isDetailed
        sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION}
        beforeEditActions={
          <MoreBar.Action
            use={InternalLink}
            to={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.SELECT_FLOW
            }
            useIcon="Upload"
          >
            Import employees
          </MoreBar.Action>
        }
      />
    ),
    isWide: true,
  },
]

export const EmployeeRecords = () => {
  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.ANY}>
        <Route
          path={
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.SELECT_FLOW
          }
        >
          <SelectBulkUploadFlow
            importEmployeesRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.IMPORT
            }
            importContractsRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.IMPORT
            }
          />
        </Route>
        <Route
          path={[
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.IMPORT,
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.SESSION,
          ]}
        >
          <BulkEmployeeUploadFlow
            isDetailed
            canConfigureBulkUploadFields
            importRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.IMPORT
            }
            sessionRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.SESSION
            }
            anyRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.ANY
            }
            onAfterConfirmRoute={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            getHeader={() => (
              <PageHeader
                title="Create new employees"
                backUrl={pathToUrl(
                  ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
                )}
              />
            )}
          />
        </Route>
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.ANY}>
        <Route
          path={[
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.IMPORT,
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.SESSION,
          ]}
        >
          <BulkContractsUploadFlow
            canConfigureBulkUploadFields
            importRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.IMPORT
            }
            sessionRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.SESSION
            }
            anyRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.ANY
            }
            onAfterConfirmRoute={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE}
            apiEndpoint={API.CONTRACTS_UPLOAD}
            getHeader={() => (
              <PageHeader
                title="Create new contracts"
                backUrl={pathToUrl(
                  ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
                )}
              />
            )}
          />
        </Route>
      </Route>
      <Route>
        <OnboardingChecklistContent config={employeeRecordsConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
