import React from 'react'

import { InviteEmployees } from '@src/features/InviteEmployees/InviteEmployees'
import { PageWrapper } from '@src/components/Page/Page'
import { OnboardingChecklistHeader } from '../components/OnboardingChecklistHeader'
import { ROUTES } from '@src/constants/routes'
import { useRouteMatch } from 'react-router-dom'

export const InviteTeamPage = () => {
  const isEmailsRoute = !!useRouteMatch(
    ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_ADMINS.EMAILS,
  )

  return (
    <PageWrapper>
      <OnboardingChecklistHeader
        title="Invite employees"
        description={
          isEmailsRoute
            ? 'Type invitee emails below, or paste as many as you need at once'
            : 'An Invitation email with onboarding instructions will be sent to your employees to get started'
        }
        backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.ALL}
        useGetBackUrl
      />
      <InviteEmployees
        emailListRoute={ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_ADMINS.EMAILS}
        reviewTableRoute={ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_ADMINS.REVIEW}
        assignAdminGroup
        closeRoute={ROUTES.ONBOARDING_CHECKLIST_V2.ALL}
      />
    </PageWrapper>
  )
}
