import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { ImportContractsDataInterface } from '@src/interfaces/importContracts'
import {
  onboardingContractsEndDateColumn,
  onboardingContractsStartDateColumn,
  onboardingContractsCompanyEntityColumn,
  onboardingContractsContractTermColumn,
  onboardingContractsContractTypeColumn,
  onboardingContractsEmailColumn,
  onboardingContractsFullTimeEquivalentColumn,
  onboardingContractsLocationColumn,
  onboardingContractsNoticePeriodAfterProbationColumn,
  onboardingContractsNoticePeriodAfterProbationUnitColumn,
  onboardingContractsNoticePeriodDuringProbationColumn,
  onboardingContractsNoticePeriodDuringProbationUnitColumn,
  onboardingContractsSalaryAmountColumn,
  onboardingContractsSalaryCurrencyColumn,
  onboardingContractsSalaryPaymentFrequencyColumn,
  onboardingContractsSeniorityColumn,
  onboardingContractsSpecialisationColumn,
  onboardingContractsSpecialisationSenioritySublevelColumn,
  onboardingContractsStatusColumn,
  onboardingContractsTitleColumn,
  onboardingContractsWeeklyWorkingHoursColumn,
  onboardingContractsSalaryTimeUnitColumn,
} from '@src/constants/columns/contractsV2'

export const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportContractsDataInterface>> => ({
  cells: [
    {
      ...onboardingContractsEmailColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsContractTypeColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsContractTermColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsStartDateColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsEndDateColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsLocationColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsCompanyEntityColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSalaryAmountColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSalaryCurrencyColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSalaryTimeUnitColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSalaryPaymentFrequencyColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSpecialisationColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSeniorityColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSpecialisationSenioritySublevelColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsTitleColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsWeeklyWorkingHoursColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsFullTimeEquivalentColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsNoticePeriodDuringProbationColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsNoticePeriodDuringProbationUnitColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsNoticePeriodAfterProbationColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsNoticePeriodAfterProbationUnitColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsStatusColumn(options.onChange),
      width: 200,
    },
  ],
})
