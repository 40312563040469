import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { TableNames } from '@src/constants/table'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { row } from './common'

const fields = ['contract_term'] as const

type ContractFields = typeof fields[number]

const baseFieldsForBulkEdit = ['email', 'contract_type', 'contract_term']

export const fieldsForBulkEdit = {
  location: [...baseFieldsForBulkEdit, 'location'],
}

const getHeaderTitle = (type?: string) => {
  switch (type) {
    case 'location':
      return 'Review bulk assigned location'
    default:
      return 'Import contracts'
  }
}

type Props = {
  getHeader: (title: string) => React.ReactNode
  importRoute: string
  sessionRoute: string
  anyRoute: string
  apiEndpoint: string
  canConfigureBulkUploadFields?: boolean
  onAfterConfirmRoute?: string
  category?: 'employee_upload' | 'contract_bulk_upload'
}
export const BulkContractsUploadFlow = ({
  getHeader,
  importRoute,
  sessionRoute,
  anyRoute,
  apiEndpoint,
  canConfigureBulkUploadFields,
  onAfterConfirmRoute,
  category = 'contract_bulk_upload',
}: Props) => {
  const params = useParams<{ type?: ContractFields }>()

  const { title } = useMemo(
    () => ({ title: getHeaderTitle(params?.type) }),
    [params?.type],
  )

  return (
    <>
      <BulkDataUploadV2
        importRoute={importRoute}
        sessionRoute={sessionRoute}
        anyRoute={anyRoute}
        apiEndpoint={apiEndpoint}
        category={category}
        name="contract"
        tableName={TableNames.ImportEmployeesV2}
        row={row}
        entity="employee"
        templateParams={{ version: '2' }}
        header={getHeader(title)}
        canConfigureBulkUploadFields={canConfigureBulkUploadFields}
        onAfterConfirmRoute={onAfterConfirmRoute}
      />
    </>
  )
}
